import React from "react";
import { Redirect, Route } from "react-router-dom";
import { RouteComponentProps, RouteProps } from "react-router";
import { useSelector } from "react-redux";

import { Path } from "@src/Path";
import { AuthSelectors } from "@redux/selectors/AuthSelectors";

type Props = RouteProps;

export const GuestRoute = (props: Props) => {
    const isLoggedIn = useSelector(AuthSelectors.isLoggedIn);
    const { component: Component, ...rest } = props;
    return (
        <Route
            {...rest}
            render={(props: RouteComponentProps): React.ReactNode | null => {
                if (isLoggedIn) {
                    return <Redirect to={{ pathname: Path.home }} />;
                } else {
                    if (Component) {
                        return <Component {...props} />;
                    } else if (rest.render) {
                        return rest.render(props);
                    }
                    return null;
                }
            }}
        />
    );
};
