import React from "react";
import ReactDOM from "react-dom";
import { Log } from "@utils/Log";
import { Provider } from "react-redux";
import { persistor, store } from "@redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import { Theme } from "@theme/Theme";
import { GlobalStyle } from "@theme/GlobalStyle";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { MobileWrapper } from "@components/MobileWrapper";
import { ApolloProvider } from "@apollo/client";
import { GraphQLClient } from "@api/graphql/GraphQLClient";

Log.init();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ApolloProvider client={GraphQLClient.client}>
                    <ThemeProvider theme={Theme}>
                        <GlobalStyle />

                        <MobileWrapper>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </MobileWrapper>
                    </ThemeProvider>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
