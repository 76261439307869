import { DocumentNode, gql } from "@apollo/client";

export class Fragments {
    public static readonly account: DocumentNode = gql`
        fragment Account on Account {
            id
            name
            email
            role
        }
    `;

    public static readonly authResult: DocumentNode = gql`
        fragment AuthResult on AuthResult {
            account {
                ...Account
            }
            token
        }
        ${Fragments.account}
    `;

    public static readonly door: DocumentNode = gql`
        fragment Door on Door {
            enabled
            location
        }
    `;

    public static readonly requirePassResult: DocumentNode = gql`
        fragment RequirePassResult on RequirePassResult {
            url
            expiresAt
        }
    `;
}
