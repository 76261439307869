import { Gql } from "@api/Api";
import { ActionsUnion, createAction } from "./ActionHelpers";

export enum SettingsActionType {
    setDoors = "@settings/setDoors",
}

export const SettingsActions = {
    setDoors: (doors: Gql.Door[]) => createAction(SettingsActionType.setDoors, { doors }),
};

export type SettingsAction = ActionsUnion<typeof SettingsActions>;
