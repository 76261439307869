import { DefaultTheme } from "styled-components";

function parseRGBHex(rgbHex: string): [r: number, g: number, b: number] {
    const result = /^#(?:([0-9A-Fa-f])([0-9A-Fa-f])([0-9A-Fa-f])|([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2}))$/.exec(rgbHex);
    if (!result) {
        throw new Error(`Invalid RGB hex string: "${rgbHex}"`);
    }

    const [, r1, g1, b1, r2, g2, b2] = result;
    const r = parseInt((r1 ? `${r1}${r1}` : r2) ?? "0", 16);
    const g = parseInt((g1 ? `${g1}${g1}` : g2) ?? "0", 16);
    const b = parseInt((b1 ? `${b1}${b1}` : b2) ?? "0", 16);

    return [r, g, b];
}

export function hexToRGBA(hex: string, opacity = 1): string {
    const [r, g, b] = parseRGBHex(hex);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export enum BreakPoint {
    small = "small",
    medium = "medium",
    large = "large",
    xlarge = "xlarge",
    xxlarge = "xxlarge",
}

export const breakpoints: { [key in BreakPoint]: number } = {
    small: 375,
    medium: 768,
    large: 1024,
    xlarge: 1280,
    xxlarge: 1920,
};

function customMediaQuery(mediaQuery: string): string {
    return `@media (${mediaQuery})`;
}

export const bp: { [key in BreakPoint]: string } & {
    custom: (mediaQuery: string) => string;
} = {
    custom: customMediaQuery,
    small: customMediaQuery(`min-width: ${breakpoints.small}px`),
    medium: customMediaQuery(`min-width: ${breakpoints.medium}px`),
    large: customMediaQuery(`min-width: ${breakpoints.large}px`),
    xlarge: customMediaQuery(`min-width: ${breakpoints.xlarge}px`),
    xxlarge: customMediaQuery(`min-width: ${breakpoints.xxlarge}px`),
};

export enum Color {
    greenPrimary = "#7EBF0D",
    greenBright = "#DFFFA8",
    greenMed = "#568700",

    greenDullBright = "#D6E6BA",
    greenDullDark = "#4B4F43",
    greenBlack = "#182600",
    white = "#FFFFFF",

    greenAlphaBorder = "rgba(126, 191, 13, 0.3)",

    greyBright = "#EFF0F7",
    greyMed = "#8588A3",
    greyDark = "#4E4B66",

    successBright = "#00CC67",
    successDark = "#00994D",
    successDull = "#F2FFF9",

    errorBright = "#E40173",
    errorDark = "#98014C",
    errorDull = "#FFF2F9",
}

export const Theme: DefaultTheme = {
    button: {
        huge: {
            padding: "25px 33px",
            radius: "16px",
            fontSize: "17px",
            fontWeight: 600,
            iconHeight: "22px",
            iconSpacing: "16px",
        },
        large: {
            padding: "20px 32px",
            radius: "12px",
            fontSize: "15px",
            fontWeight: 600,
            iconHeight: "20px",
            iconSpacing: "16px",
        },
        medium: {
            padding: "17px 25px",
            radius: "12px",
            fontSize: "15px",
            fontWeight: 600,
            iconHeight: "22px",
            iconSpacing: "16px",
        },
        small: {
            padding: "15px 23px",
            radius: "8px",
            fontSize: "13px",
            fontWeight: 500,
            iconHeight: "15px",
            iconSpacing: "13px",
        },
    },
    buttonColors: {
        default: Color.greenPrimary,
        hover: Color.greenMed,
        focus: Color.greenAlphaBorder,
        pressed: Color.greenBlack,
        disabled: Color.greenDullBright,
        textColor: Color.white,
    },
};
