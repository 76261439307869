import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { Path } from "./Path";

import { SandboxPage } from "@pages/sandbox/SandboxPage";
import { GuestRoute } from "@components/routes/GuestRoute";
import { LoginPage } from "@pages/login/LoginPage";
import { HomePage } from "@pages/home/HomePage";
import { PrivateRoute } from "@components/routes/PrivateRoute";

export const App = () => {
    return (
        <Switch>
            <GuestRoute exact path={Path.login} component={LoginPage} />
            <PrivateRoute path={Path.home} component={HomePage} />

            {process.env.NODE_ENV === "development" && <GuestRoute component={SandboxPage} path={Path.sandbox} />}

            <Redirect from="*" to={Path.login} />
        </Switch>
    );
};
