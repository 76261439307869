import gql from "graphql-tag";
import { DocumentNode } from "graphql";

import { Fragments } from "./Fragments";

export class Mutations {
    public static readonly login: DocumentNode = gql`
        mutation login($input: LoginInput!) {
            login(input: $input) {
                ...AuthResult
            }
        }
        ${Fragments.authResult}
    `;

    public static readonly loginOauth: DocumentNode = gql`
        mutation loginOAuth($provider: OAuthProvider!, $token: String!) {
            loginOAuth(provider: $provider, token: $token) {
                ...AuthResult
            }
        }
        ${Fragments.authResult}
    `;

    public static readonly logout: DocumentNode = gql`
        mutation logout {
            logout
        }
    `;

    public static readonly openDoor: DocumentNode = gql`
        mutation openDoor($location: DoorLocation!) {
            openDoor(location: $location)
        }
    `;

    public static readonly requestPass: DocumentNode = gql`
        mutation requestPass($platform: PassPlatform!) {
            requirePass(platform: $platform) {
                ...RequirePassResult
            }
        }
        ${Fragments.requirePassResult}
    `;
}
