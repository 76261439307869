import React from "react";
import styled from "styled-components";
import { Button } from "@components/Button";
import IconStar from "@components/svg/IconStar";

export const SandboxPage = () => {
    return (
        <>
            <h3>Primary</h3>
            <Container>
                <Column>
                    <h4>Huge</h4>
                    <Button.Primary btnLabel={"Button"} size={"huge"} icon={<IconStar />} disabled={false} />
                    <Button.Primary btnLabel={"Button"} size={"huge"} icon={<IconStar />} iconAlign={"right"} disabled={false} />
                    <Button.Primary btnLabel={"Button"} size={"huge"} disabled={false} />
                    <Button.Primary size={"huge"} icon={<IconStar />} disabled={false} />
                    <Button.Primary btnLabel={"Button"} size={"huge"} disabled={true} />
                </Column>
                <Column>
                    <h4>Large</h4>
                    <Button.Primary btnLabel={"Button"} size={"large"} icon={<IconStar />} disabled={false} />
                    <Button.Primary btnLabel={"Button"} size={"large"} icon={<IconStar />} iconAlign={"right"} disabled={false} />
                    <Button.Primary btnLabel={"Button"} size={"large"} disabled={false} />
                    <Button.Primary size={"large"} icon={<IconStar />} disabled={false} />
                    <Button.Primary btnLabel={"Button"} size={"large"} disabled={true} />
                </Column>
                <Column>
                    <h4>Medium</h4>
                    <Button.Primary btnLabel={"Button"} size={"medium"} icon={<IconStar />} disabled={false} />
                    <Button.Primary btnLabel={"Button"} size={"medium"} icon={<IconStar />} iconAlign={"right"} disabled={false} />
                    <Button.Primary btnLabel={"Button"} size={"medium"} disabled={false} />
                    <Button.Primary size={"medium"} icon={<IconStar />} disabled={false} />
                    <Button.Primary btnLabel={"Button"} size={"medium"} disabled={true} />
                </Column>
                <Column>
                    <h4>Small</h4>
                    <Button.Primary btnLabel={"Button"} size={"small"} icon={<IconStar />} disabled={false} />
                    <Button.Primary btnLabel={"Button"} size={"small"} icon={<IconStar />} iconAlign={"right"} disabled={false} />
                    <Button.Primary btnLabel={"Button"} size={"small"} disabled={false} />
                    <Button.Primary size={"small"} icon={<IconStar />} disabled={false} />
                    <Button.Primary btnLabel={"Button"} size={"small"} disabled={true} />
                </Column>
            </Container>
            <h3>Secondary</h3>
            <Container>
                <Column>
                    <h4>Huge</h4>
                    <Button.Secondary btnLabel={"Button"} size={"huge"} icon={<IconStar />} disabled={false} />
                    <Button.Secondary btnLabel={"Button"} size={"huge"} icon={<IconStar />} iconAlign={"right"} disabled={false} />
                    <Button.Secondary btnLabel={"Button"} size={"huge"} disabled={false} />
                    <Button.Secondary size={"huge"} icon={<IconStar />} disabled={false} />
                    <Button.Secondary btnLabel={"Button"} size={"huge"} disabled={true} />
                </Column>
                <Column>
                    <h4>Large</h4>
                    <Button.Secondary btnLabel={"Button"} size={"large"} icon={<IconStar />} disabled={false} />
                    <Button.Secondary btnLabel={"Button"} size={"large"} icon={<IconStar />} iconAlign={"right"} disabled={false} />
                    <Button.Secondary btnLabel={"Button"} size={"large"} disabled={false} />
                    <Button.Secondary size={"large"} icon={<IconStar />} disabled={false} />
                    <Button.Secondary btnLabel={"Button"} size={"large"} disabled={true} />
                </Column>
                <Column>
                    <h4>Medium</h4>
                    <Button.Secondary btnLabel={"Button"} size={"medium"} icon={<IconStar />} disabled={false} />
                    <Button.Secondary btnLabel={"Button"} size={"medium"} icon={<IconStar />} iconAlign={"right"} disabled={false} />
                    <Button.Secondary btnLabel={"Button"} size={"medium"} disabled={false} />
                    <Button.Secondary size={"medium"} icon={<IconStar />} disabled={false} />
                    <Button.Secondary btnLabel={"Button"} size={"medium"} disabled={true} />
                </Column>
                <Column>
                    <h4>Small</h4>
                    <Button.Secondary btnLabel={"Button"} size={"small"} icon={<IconStar />} disabled={false} />
                    <Button.Secondary btnLabel={"Button"} size={"small"} icon={<IconStar />} iconAlign={"right"} disabled={false} />
                    <Button.Secondary btnLabel={"Button"} size={"small"} disabled={false} />
                    <Button.Secondary size={"small"} icon={<IconStar />} disabled={false} />
                    <Button.Secondary btnLabel={"Button"} size={"small"} disabled={true} />
                </Column>
            </Container>
            <h3>Text</h3>
            <Container>
                <Column>
                    <h4>Huge</h4>
                    <Button.Text btnLabel={"Button"} size={"huge"} icon={<IconStar />} disabled={false} />
                    <Button.Text btnLabel={"Button"} size={"huge"} icon={<IconStar />} iconAlign={"right"} disabled={false} />
                    <Button.Text btnLabel={"Button"} size={"huge"} disabled={false} />
                    <Button.Text size={"huge"} icon={<IconStar />} disabled={false} />
                    <Button.Text btnLabel={"Button"} size={"huge"} disabled={true} />
                </Column>
                <Column>
                    <h4>Large</h4>
                    <Button.Text btnLabel={"Button"} size={"large"} icon={<IconStar />} disabled={false} />
                    <Button.Text btnLabel={"Button"} size={"large"} icon={<IconStar />} iconAlign={"right"} disabled={false} />
                    <Button.Text btnLabel={"Button"} size={"large"} disabled={false} />
                    <Button.Text size={"large"} icon={<IconStar />} disabled={false} />
                    <Button.Text btnLabel={"Button"} size={"large"} disabled={true} />
                </Column>
                <Column>
                    <h4>Medium</h4>
                    <Button.Text btnLabel={"Button"} size={"medium"} icon={<IconStar />} disabled={false} />
                    <Button.Text btnLabel={"Button"} size={"medium"} icon={<IconStar />} iconAlign={"right"} disabled={false} />
                    <Button.Text btnLabel={"Button"} size={"medium"} disabled={false} />
                    <Button.Text size={"medium"} icon={<IconStar />} disabled={false} />
                    <Button.Text btnLabel={"Button"} size={"medium"} disabled={true} />
                </Column>
                <Column>
                    <h4>Small</h4>
                    <Button.Text btnLabel={"Button"} size={"small"} icon={<IconStar />} disabled={false} />
                    <Button.Text btnLabel={"Button"} size={"small"} icon={<IconStar />} iconAlign={"right"} disabled={false} />
                    <Button.Text btnLabel={"Button"} size={"small"} disabled={false} />
                    <Button.Text size={"small"} icon={<IconStar />} disabled={false} />
                    <Button.Text btnLabel={"Button"} size={"small"} disabled={true} />
                </Column>
            </Container>
        </>
    );
};

const Column = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    button {
        margin-top: 16px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 1rem;

    ${Column} {
        margin-left: 16px;
    }
`;
