import { Gql } from "@api/Api";
import { AuthResult } from "@api/graphql/types";
import { ActionsUnion, createAction } from "./ActionHelpers";

export enum AuthActionType {
    logout = "@auth/logout",
    updateAuthData = "@auth/updateAuthData",
    updateAuthToken = "@auth/updateAuthToken",
    updateAccount = "@auth/updateAccount",
}

export const AuthActions = {
    logout: () => createAction(AuthActionType.logout),
    updateAuthData: (authResult: AuthResult) => createAction(AuthActionType.updateAuthData, { authResult }),
    updateAuthToken: (authToken: string) => createAction(AuthActionType.updateAuthToken, { authToken }),
    updateAccount: (account: Gql.Account) => createAction(AuthActionType.updateAccount, { account }),
};

export type AuthAction = ActionsUnion<typeof AuthActions>;
