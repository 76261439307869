import React from "react";
import { createIntl, IntlShape } from "react-intl";
import { localeHU } from "./locales/hu";
import { MessageDescriptor } from "@formatjs/intl/src/types";
import { flattenMessages } from "./I18nHelpers";
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import { Options as IntlMessageFormatOptions } from "intl-messageformat/src/core";

enum Language {
    hu = "hu",
}

export class I18n {
    private static intl: IntlShape = createIntl({
        locale: Language.hu,
        messages: flattenMessages(localeHU),
    });

    public static formatMessage = I18n.intl.formatMessage;

    public static formatNumber = I18n.intl.formatNumber;

    public static formatHTMLMessage(descriptor: MessageDescriptor, values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>, opts?: IntlMessageFormatOptions) {
        return <span dangerouslySetInnerHTML={{ __html: I18n.formatMessage(descriptor, values, opts) as string }} />;
    }
}
