import React from "react";
import styled from "styled-components";
import { Header } from "@components/Header";

export const AppLayout = ({ children }: React.PropsWithChildren<{}>) => {
    return (
        <>
            <Header />
            <AppLayoutInner>{children}</AppLayoutInner>
        </>
    );
};

const AppLayoutInner = styled.div`
    height: 100%;
    padding-top: 30px;
`;
