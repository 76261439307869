import { applyMiddleware, compose, createStore, Middleware, Store } from "redux";
import { ApplicationState, rootReducer } from "@redux/reducers";
import { batchDispatchMiddleware, enableBatching } from "redux-batched-actions";
import { persistStore } from "redux-persist";

const middlewares: Middleware[] = [batchDispatchMiddleware];

const composeEnhancers = process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
const enhancer = composeEnhancers ? composeEnhancers(applyMiddleware(...middlewares)) : undefined;

const store: Store<ApplicationState> = createStore(enableBatching(rootReducer), enhancer);

const persistor = persistStore(store);

export { persistor, store };
