/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, ApolloError, ApolloQueryResult, createHttpLink, FetchResult, InMemoryCache, MutationOptions, QueryOptions } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { store } from "@redux/Store";
import { onError } from "@apollo/client/link/error";
import { Log } from "@utils/Log";
import { AuthActions } from "@redux/actions/AuthActions";
import { ApiError, ApiErrorCode } from "@api/ApiError";

export class GraphQLClient {
    private static readonly httpLink = createHttpLink({
        uri: process.env.REACT_APP_GRAPHQL_API_URL,
    });

    private static readonly authLink = setContext((_, prevContext) => {
        const authToken = store.getState().auth.authToken;
        if (!authToken) {
            return prevContext;
        }

        return {
            ...prevContext,
            headers: {
                ...prevContext.headers,
                authorization: `Bearer ${authToken}`,
            },
        };
    });

    private static readonly errorLink = onError(error => {
        if (error.response?.errors && error.response?.errors.length > 0) {
            const gqlError = error.response?.errors[0];

            if (gqlError?.extensions?.code === `E_${ApiErrorCode.UNAUTHORIZED}`) {
                store.dispatch(AuthActions.logout());
            }
        }
    });

    /*private static readonly retryLink = onError(errorResponse => {
        const { graphQLErrors, operation, forward } = errorResponse;
        const refreshToken: string | null = store.getState().auth.refreshToken;

        if (graphQLErrors && refreshToken) {
            for (const graphQLError of graphQLErrors) {
                if (!graphQLError.extensions || ["login", "refreshToken", "forgotPassword", "resetPassword"].some((request: string) => operation.operationName === request)) {
                    continue;
                }

                if (graphQLError.message === ErrorConst.unauthorized) {
                    Log.debug("Token is outdated, try to get a new one…");
                    return new Observable((observer): void => {
                        GraphQLClient.mutate<refreshToken, refreshTokenVariables>({ mutation: Mutations.refreshToken, variables: { refreshToken } })
                            .then((response: refreshToken): void => {
                                const subscriber = {
                                    next: observer.next.bind(observer),
                                    error: observer.error.bind(observer),
                                    complete: observer.complete.bind(observer),
                                };
                                const newToken = response.refreshToken;
                                store.dispatch(AuthActions.updateAuthToken(newToken, refreshToken));
                                operation.setContext({
                                    headers: {
                                        ...operation.getContext().headers,
                                        Authorization: `Bearer ${newToken}`,
                                    },
                                });
                                forward(operation).subscribe(subscriber);
                            })
                            .catch((error: ApiError) => {
                                Log.debug("RefreshToken failed", error);
                                store.dispatch(AuthActions.logout());
                                observer.error(errorResponse);
                                //                                store.dispatch(AlertActions.show({ message: I18n.formatMessage({ id: "messages.unauthenticated" }), type: AlertType.error }));
                            });
                    });
                }
            }
        }
    });*/

    public static readonly client = new ApolloClient({
        link: GraphQLClient.authLink.concat(GraphQLClient.errorLink).concat(GraphQLClient.httpLink),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "network-only",
                errorPolicy: "ignore",
            },
            query: {
                fetchPolicy: "network-only",
                errorPolicy: "all",
            },
        },
    });

    public static async mutate<R, V = {}>(options: MutationOptions<R, V>): Promise<R> {
        try {
            const response = await GraphQLClient.client.mutate<R, V>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof ApiError) {
                throw error;
            }
            throw GraphQLClient.handleError<R, V>(error as ApolloError | Error, options);
        }
    }

    public static async query<R, V = {}>(options: QueryOptions<V>): Promise<R> {
        try {
            const response: ApolloQueryResult<R> = await GraphQLClient.client.query<R>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof ApiError) {
                throw error;
            }
            throw GraphQLClient.handleError<R, V>(error as ApolloError | Error);
        }
    }

    private static getResult<R>(response: ApolloQueryResult<R> | FetchResult<R> | any): R {
        if (response.errors?.length > 0) {
            throw GraphQLClient.handleError(response.errors[0]);
        }

        return response.data;
    }

    private static handleError<R, V>(error: ApolloError | Error, options?: MutationOptions<R, V> | QueryOptions<R, V>): any {
        if (error instanceof ApolloError && error.graphQLErrors?.length > 0) {
            const currentError = error.graphQLErrors[0];
            Log.error(currentError);
        } else {
            return new ApiError(error.message);
        }
    }
}
