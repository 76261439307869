import * as React from "react";
import { SVGProps } from "react";

const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 212 212" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
        <path d="M191.35 0H20C8.954 0 0 8.954 0 20v171.35c0 11.046 8.954 20 20 20h171.35c11.046 0 20-8.954 20-20V20c0-11.046-8.954-20-20-20Z" fill="#7EBF0D" />
        <path
            d="M70.805 81.336a3.203 3.203 0 0 1-4.53 0L64.9 79.96a5.148 5.148 0 0 1 0-7.28l2.7-2.7a8.966 8.966 0 0 1 12.68 0l22.995 22.995a5.764 5.764 0 0 1-8.15 8.15l-19.79-19.79a3.203 3.203 0 0 0-4.53 0ZM126.47 111.761c3.29 0 6.39 1.28 8.72 3.61 2.33 2.33 3.61 5.42 3.61 8.72 0 3.29-1.28 6.39-3.61 8.72a12.237 12.237 0 0 1-8.72 3.61c-3.29 0-6.39-1.28-8.72-3.61-4.81-4.81-4.81-12.63 0-17.44 2.33-2.33 5.42-3.61 8.72-3.61Zm0-11.3a23.58 23.58 0 0 0-16.71 6.92c-9.23 9.23-9.23 24.18 0 33.41a23.563 23.563 0 0 0 16.71 6.92c6.05 0 12.09-2.31 16.71-6.92 9.23-9.23 9.23-24.18 0-33.41a23.563 23.563 0 0 0-16.71-6.92Z"
            fill="#fff"
        />
    </svg>
);

export default SvgLogo;
