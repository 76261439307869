/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: login
// ====================================================

export interface login_login_account {
    __typename: "Account";
    id: any;
    name: string;
    email: string;
    role: string[];
}

export interface login_login {
    __typename: "AuthResult";
    account: login_login_account;
    token: string;
}

export interface login {
    login: login_login;
}

export interface loginVariables {
    input: LoginInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: loginOAuth
// ====================================================

export interface loginOAuth_loginOAuth_account {
    __typename: "Account";
    id: any;
    name: string;
    email: string;
    role: string[];
}

export interface loginOAuth_loginOAuth {
    __typename: "AuthResult";
    account: loginOAuth_loginOAuth_account;
    token: string;
}

export interface loginOAuth {
    loginOAuth: loginOAuth_loginOAuth;
}

export interface loginOAuthVariables {
    provider: OAuthProvider;
    token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: logout
// ====================================================

export interface logout {
    logout: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: openDoor
// ====================================================

export interface openDoor {
    openDoor: boolean;
}

export interface openDoorVariables {
    location: DoorLocation;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: requestPass
// ====================================================

export interface requestPass_requirePass {
    __typename: "RequirePassResult";
    url: string;
    expiresAt: any;
}

export interface requestPass {
    requirePass: requestPass_requirePass;
}

export interface requestPassVariables {
    platform: PassPlatform;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: me
// ====================================================

export interface me_me {
    __typename: "Account";
    id: any;
    name: string;
    email: string;
    role: string[];
}

export interface me {
    me: me_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listDoors
// ====================================================

export interface listDoors_listDoors {
    __typename: "Door";
    enabled: boolean;
    location: DoorLocation;
}

export interface listDoors {
    listDoors: listDoors_listDoors[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: qrCode
// ====================================================

export interface qrCode {
    qrCode: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Account
// ====================================================

export interface Account {
    __typename: "Account";
    id: any;
    name: string;
    email: string;
    role: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AuthResult
// ====================================================

export interface AuthResult_account {
    __typename: "Account";
    id: any;
    name: string;
    email: string;
    role: string[];
}

export interface AuthResult {
    __typename: "AuthResult";
    account: AuthResult_account;
    token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Door
// ====================================================

export interface Door {
    __typename: "Door";
    enabled: boolean;
    location: DoorLocation;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RequirePassResult
// ====================================================

export interface RequirePassResult {
    __typename: "RequirePassResult";
    url: string;
    expiresAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DoorLocation {
    Front = "Front",
    Rear = "Rear",
}

export enum OAuthProvider {
    APPLE = "APPLE",
    GOOGLE = "GOOGLE",
}

export enum PassPlatform {
    ANDROID = "ANDROID",
    IOS = "IOS",
}

export interface LoginInput {
    email: string;
    password: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
