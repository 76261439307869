export class Path {
    public static readonly root: string = "/";

    public static readonly login: string = "/login";

    public static readonly home: string = "/home";

    public static readonly qr: string = "/qr";

    public static readonly sandbox: string = "/sandbox";
}
