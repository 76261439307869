import React, { useState } from "react";
import styled, { css } from "styled-components";
import IconLockUnlocked from "@components/svg/IconLockUnlocked";
import { Color } from "@theme/Theme";
import { useMutation, useQuery } from "@apollo/client";
import { DoorLocation, listDoors, openDoor, openDoorVariables, qrCode } from "@api/graphql/types";
import { Queries } from "@api/graphql/queries/Queries";
import { Mutations } from "@api/graphql/queries/Mutations";
import IconLockLocked from "@components/svg/IconLockLocked";
import { Wallet } from "@components/Wallet";

export const HomePage = () => {
    const [openedDoor, setOpenedDoor] = useState<DoorLocation | null>(null);
    const [hasError, setHasError] = useState(false);
    const { loading: doorLoading, data: doorData } = useQuery<listDoors>(Queries.listDoors, {
        fetchPolicy: "cache-first",
    });
    const { loading: qrLoading, data: qrData } = useQuery<qrCode>(Queries.qrCode, {
        fetchPolicy: "cache-first",
    });
    const [openDoor] = useMutation<openDoor, openDoorVariables>(Mutations.openDoor);

    const onOpen = async (location: DoorLocation) => {
        if (openedDoor !== null) {
            return;
        }

        try {
            await openDoor({ variables: { location } });
            setOpenedDoor(location);
            if ("vibrate" in navigator) {
                window.navigator.vibrate(100);
            }
            setTimeout(() => {
                setOpenedDoor(null);
            }, 3000);
        } catch (e) {
            setHasError(true);
            setTimeout(() => {
                setHasError(false);
            }, 3000);
            console.log(e);
        }
    };

    return (
        <Wrapper>
            {(doorLoading || qrLoading) && <>Pillanat...</>}
            {qrData && qrData.qrCode !== null && (
                <>
                    <QrCode src={qrData.qrCode} />
                </>
            )}
            {doorData && doorData.listDoors.length > 0 && (
                <DoorButtonWrapper>
                    {doorData.listDoors.map(item => (
                        <DoorButton key={`door-${item.location}`} onClick={() => onOpen(item.location)} isOpened={openedDoor === item.location} hasError={hasError}>
                            <DoorButtonInner>
                                <DoorButtonIcon>{openedDoor === item.location ? <IconLockUnlocked /> : <IconLockLocked />}</DoorButtonIcon>
                                <DoorButtonLabel>{item.location === "Front" ? "Első" : "Hátsó"} ajtó</DoorButtonLabel>
                            </DoorButtonInner>
                        </DoorButton>
                    ))}
                </DoorButtonWrapper>
            )}

            <Wallet />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 90px 0 86px 0;
`;

const QrCode = styled.img`
    width: 80%;
    margin-bottom: 30px;
`;

const DoorButtonLabel = styled.label`
    margin-top: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: ${Color.greyDark};
`;

const DoorButtonIcon = styled.div`
    font-size: 0;

    svg {
        height: 24px;
        width: auto;
        color: ${Color.greenPrimary};
    }
`;

const DoorButtonInner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
`;

const DoorButton = styled.button<{ isOpened: boolean; hasError: boolean }>`
    display: flex;
    background-color: ${Color.white};
    transition: 200ms ease-in-out all;
    box-shadow: 0px 10px 20px rgba(133, 136, 162, 0.2);
    border: 0;
    border-radius: 20px;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: ${Color.white};
        box-shadow: 0px 20px 40px rgba(133, 136, 162, 0.15);
        cursor: pointer;
    }

    ${props =>
        props.isOpened &&
        css`
            ${DoorButtonLabel} {
                color: ${Color.greenPrimary};
            }
        `}
    margin: 0 12px;
`;

const DoorButtonWrapper = styled.div`
    display: flex;
    margin-bottom: 16px;
`;
