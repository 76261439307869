import { ReduxAction } from "@redux/actions/ReduxAction";
import localStorage from "redux-persist/es/storage";
import { PersistConfig } from "redux-persist/es/types";
import persistCombineReducers from "redux-persist/lib/persistCombineReducers";

import { AuthReducer, AuthReducerState } from "./AuthReducer";
import { SettingsReducer, SettingsReducerState } from "@redux/reducers/SettingsReducer";

export interface ApplicationState {
    auth: AuthReducerState;
    settings: SettingsReducerState;
}

const config: PersistConfig<ApplicationState> = {
    key: "bfgate",
    storage: localStorage,
    debug: process.env.REACT_APP_DEBUG_MODE === "true",
    whitelist: ["auth", "settings"],
};

export const rootReducer = persistCombineReducers<ApplicationState, ReduxAction>(config, {
    auth: AuthReducer,
    settings: SettingsReducer,
});
