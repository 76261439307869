import { createGlobalStyle } from "styled-components";
import { Color } from "@theme/Theme";

export const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Poppins', sans-serif;
        margin: 0;
        background-color: ${Color.greyBright};
        color: ${Color.greyDark};
    }
    
    * {
      box-sizing: border-box;
    }
`;
