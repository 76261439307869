import { ApplicationState } from "@redux/reducers";
import { Account } from "@api/graphql/types";

export const AuthSelectors = Object.freeze({
    isLoggedIn: (state: ApplicationState): boolean => {
        return !!state.auth.account && !!state.auth.authToken;
    },
    account: (state: ApplicationState): Account | null => {
        return state.auth.account;
    },
});
