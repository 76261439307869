import React from "react";
import styled, { css } from "styled-components";

export type ButtonSize = "huge" | "large" | "medium" | "small";

type Props = {
    btnLabel?: string;
    size: ButtonSize;
    icon?: React.ReactElement;
    iconAlign?: "left" | "right";
    disabled?: boolean;
    isLoading?: boolean;
    type?: "button" | "submit";
    block?: boolean;
} & Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "ref">;

const ButtonComponent: React.FC<Props> = ({ btnLabel, icon, iconAlign, isLoading, disabled, ...otherPros }: React.PropsWithChildren<Props>) => {
    return (
        <button {...otherPros} disabled={isLoading || disabled}>
            {icon && iconAlign !== "right" && (
                <ButtonIcon $hasLabel={!!btnLabel} size={otherPros.size}>
                    {icon}
                </ButtonIcon>
            )}
            {btnLabel ? <span>{btnLabel}</span> : null}
            {icon && iconAlign === "right" && (
                <ButtonIcon $hasLabel={!!btnLabel} $align={iconAlign} size={otherPros.size}>
                    {icon}
                </ButtonIcon>
            )}
        </button>
    );
};

export const ButtonIcon = styled.span<{ size: ButtonSize; $align?: "left" | "right"; $hasLabel: boolean }>`
    font-size: 0;

    ${props =>
        props.$hasLabel
            ? props.$align === "right"
                ? `
            margin-left: ${props.theme.button[props.size].iconSpacing};
        `
                : `
        margin-right: ${props.theme.button[props.size].iconSpacing};
    `
            : ""}

    svg {
        height: ${props => props.theme.button[props.size].iconHeight};
        width: auto;
        font-size: 0;
        line-height: 200%;
    }
`;

const ButtonStyle = css<{ size: ButtonSize; block?: boolean }>`
    display: flex;
    width: ${props => (props.block ? "100%" : "fit-content")};
    justify-content: center;
    align-items: center;
    padding: ${props => props.theme.button[props.size].padding};
    border-radius: ${props => props.theme.button[props.size].radius};
    font-size: ${props => props.theme.button[props.size].fontSize};
    font-weight: ${props => props.theme.button[props.size].fontWeight};
    letter-spacing: 0.75px;
    border: 0;
    transition: 200ms ease-in-out all;

    &:disabled {
        &:hover {
            cursor: not-allowed;
        }
    }
`;

const PrimaryButton = styled(ButtonComponent)<{ size: ButtonSize }>`
    ${ButtonStyle};
    color: ${props => props.theme.buttonColors.textColor};

    background: ${props => props.theme.buttonColors.default};

    &:hover {
        background: ${props => props.theme.buttonColors.hover};
        cursor: pointer;
    }

    &:focus {
        box-shadow: 0 0 0 8px ${props => props.theme.buttonColors.focus};
    }

    &:disabled {
        background: ${props => props.theme.buttonColors.disabled};
    }
`;

const SecondaryButton = styled(ButtonComponent)<{ size: ButtonSize }>`
    ${ButtonStyle};
    color: ${props => props.theme.buttonColors.default};

    background: transparent;
    border: 2px solid ${props => props.theme.buttonColors.default};

    &:hover {
        color: ${props => props.theme.buttonColors.hover};
        border: 2px solid ${props => props.theme.buttonColors.hover};
    }

    &:focus {
        box-shadow: 0 0 0 8px ${props => props.theme.buttonColors.focus};
    }

    &:disabled {
        color: ${props => props.theme.buttonColors.disabled};
        border: 2px solid ${props => props.theme.buttonColors.disabled};

        &:hover {
        }
    }
`;

const TextButton = styled(ButtonComponent)<{ size: ButtonSize }>`
    ${ButtonStyle};

    background: transparent;
    color: ${props => props.theme.buttonColors.default};

    &:hover {
        color: ${props => props.theme.buttonColors.hover};
    }

    &:focus {
        box-shadow: 0 0 0 8px ${props => props.theme.buttonColors.focus};
    }

    &:disabled {
        color: ${props => props.theme.buttonColors.disabled};
    }
`;

export const Button = {
    Primary: PrimaryButton,
    Secondary: SecondaryButton,
    Text: TextButton,
};
