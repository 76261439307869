import { useCallback, useState } from "react";
import { loginOAuth, loginOAuthVariables, OAuthProvider } from "@api/graphql/types";
import { useMutation } from "@apollo/client";
import { Mutations } from "@api/graphql/queries/Mutations";
import { useDispatch } from "react-redux";
import { AuthActions } from "@redux/actions/AuthActions";

type SocialLoginAdapter = () => Promise<SocialLoginAdapterResult | null>;

const googleAdapter: SocialLoginAdapter = async () => {
    try {
        if (!gapi.auth2) {
            await new Promise<void>(resolve =>
                gapi.load("auth2", () => {
                    gapi.auth2.init({ client_id: process.env.NEXT_PUBLIC_FB_APP_ID });
                    resolve();
                })
            );
        }

        const auth = window.gapi.auth2.getAuthInstance();

        const user = await auth.signIn();

        const authResponse = user.getAuthResponse();
        const profileData = user.getBasicProfile();

        const email = profileData.getEmail();
        const name = `${profileData.getFamilyName()} ${profileData.getGivenName()}`;

        return { provider: OAuthProvider.GOOGLE, name, email, token: authResponse.id_token };
    } catch (e) {
        // console.log(e, JSON.stringify(e));
        // TODO maybe parse error object and provide more descriptive messages?
        if (e.error !== "popup_closed_by_user") {
            throw new Error("Google login failed");
        }
    }

    return null;
};

const Adapters: { [k in OAuthProvider]?: SocialLoginAdapter } = {
    [OAuthProvider.GOOGLE]: googleAdapter,
};

interface SocialLoginAdapterResult {
    provider: OAuthProvider;
    name?: string;
    email?: string;
    token: string;
}

interface UseSocialLogin {
    doLogin(provider: OAuthProvider): Promise<void>;
    loading: OAuthProvider | null;
    error: string | null;
}

export function useSocialLogin(): UseSocialLogin {
    const dispatch = useDispatch();
    const [oauth] = useMutation<loginOAuth, loginOAuthVariables>(Mutations.loginOauth);

    const [loading, setLoading] = useState<OAuthProvider | null>(null);
    const [error, setError] = useState<string | null>(null);

    const doLogin = useCallback(
        async (provider: OAuthProvider) => {
            const adapter = Adapters[provider];
            try {
                setLoading(provider);
                setError(null);
                const result = adapter ? await adapter() : null;
                if (!result) {
                    setLoading(null);
                    return;
                }

                const apiResult = await oauth({
                    variables: { provider, token: result.token },
                });
                if (!apiResult.data?.loginOAuth || apiResult.errors) {
                    throw apiResult.errors?.[0];
                }
                dispatch(AuthActions.updateAuthData(apiResult.data?.loginOAuth));
            } catch (e) {
                console.log(e);
                console.log(JSON.stringify(e));
                if ((e as Error)?.message) {
                    setError((e as Error).message);
                }
            } finally {
                setLoading(null);
            }
        },
        [oauth, dispatch]
    );

    return { loading: loading, error, doLogin };
}
