import React from "react";
import styled from "styled-components";
import Logo from "@components/svg/Logo";
import { Button } from "@components/Button";
import { I18n } from "@i18n/I18n";
import IconGoogle from "@components/svg/IconGoogle";
import { Color } from "@theme/Theme";
import { useSocialLogin } from "@utils/lib/useSocialLogin";
import { OAuthProvider } from "@api/graphql/types";

export const LoginPage = () => {
    const { doLogin, error, loading } = useSocialLogin();

    return (
        <LoginPageWrapper>
            <LoginLogo>
                <Logo />
            </LoginLogo>
            <GoogleLoginButton
                icon={<IconGoogle />}
                btnLabel={I18n.formatMessage({ id: "pages.login.title" })}
                size={"medium"}
                block={true}
                isLoading={loading === OAuthProvider.GOOGLE}
                onClick={() => doLogin(OAuthProvider.GOOGLE)}
            />
            {error}
            <Version>
                <Logo />
                <span>v1.0.4</span>
            </Version>
        </LoginPageWrapper>
    );
};

const Version = styled.div`
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;

    svg {
        filter: grayscale(1);
        height: 26px;
        width: auto;
        font-size: 0;
    }

    span {
        font-size: 12px;
        line-height: 12px;
        color: ${Color.greyDark};
        margin-left: 8px;
        font-weight: 500;
    }
`;

const GoogleLoginButton = styled(Button.Primary)`
    width: 100%;
    background-color: ${Color.white};
    box-shadow: 0px 10px 20px rgba(133, 136, 162, 0.2);
    margin-top: 40px;
    color: ${Color.greyDark};

    &:hover {
        background-color: ${Color.white};
        box-shadow: 0px 20px 40px rgba(133, 136, 162, 0.15);
    }
`;

const LoginLogo = styled.div`
    svg {
        height: 130px;
        width: auto;
        font-size: 0;
    }
`;

const LoginPageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px 25px;
    justify-content: center;
    align-items: center;
`;
