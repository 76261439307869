import { ReduxAction } from "@redux/actions/ReduxAction";
import { Gql } from "@api/Api";
import { SettingsActionType } from "@redux/actions/SettingsActions";

export interface SettingsReducerState {
    doors: Gql.Door[];
}

const initialState: SettingsReducerState = {
    doors: [],
};

export function SettingsReducer(state: SettingsReducerState = initialState, action: ReduxAction): SettingsReducerState {
    switch (action.type) {
        case SettingsActionType.setDoors:
            return { ...state, doors: action.payload.doors };
        default:
            return state;
    }
}
