import React from "react";
import styled from "styled-components";
import { bp } from "@theme/Theme";

export const MobileWrapper = ({ children }: React.PropsWithChildren<{}>) => {
    return <StyledMobileWrapper>{children}</StyledMobileWrapper>;
};

const StyledMobileWrapper = styled.div`
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${bp.medium} {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 375px;
        height: 792px;
        overflow-y: scroll;
    }
`;
