import { Account } from "@api/graphql/types";
import { ReduxAction } from "@redux/actions/ReduxAction";
import { AuthActionType } from "@redux/actions/AuthActions";

export interface AuthReducerState {
    authToken: string | null;
    account: Account | null;
}

const initialState: AuthReducerState = {
    authToken: null,
    account: null,
};

export function AuthReducer(state: AuthReducerState = initialState, action: ReduxAction): AuthReducerState {
    switch (action.type) {
        case AuthActionType.updateAuthData:
            return {
                ...state,
                authToken: action.payload.authResult.token,
                account: action.payload.authResult.account,
            };
        case AuthActionType.updateAuthToken:
            return { ...state, authToken: action.payload.authToken };
        case AuthActionType.updateAccount:
            return { ...state, account: action.payload.account };
        case AuthActionType.logout:
            return { ...initialState };
        default:
            return state;
    }
}
