import * as React from "react";
import { SVGProps } from "react";

const SvgIconLockUnlocked = (props: SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
        <rect x={3.333} y={11.167} width={16.333} height={10.833} rx={2} stroke="currentColor" strokeWidth={2} />
        <path
            d="M5.917 6.5a1 1 0 1 0 2 0h-2ZM11.5 1.917v-1 1ZM7.917 6.5c0-.95.377-1.862 1.05-2.534L7.551 2.552A5.583 5.583 0 0 0 5.917 6.5h2Zm1.05-2.534a3.583 3.583 0 0 1 2.533-1.05v-2c-1.48 0-2.9.589-3.948 1.636l1.414 1.414Zm2.533-1.05c.95 0 1.862.378 2.534 1.05l1.414-1.414A5.583 5.583 0 0 0 11.5.917v2Zm2.534 1.05a3.583 3.583 0 0 1 1.05 2.534h2c0-1.48-.589-2.901-1.636-3.948l-1.414 1.414Zm1.05 2.534v4.583h2V6.5h-2Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIconLockUnlocked;
