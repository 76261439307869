import * as React from "react";
import { SVGProps } from "react";

const SvgIconLockLocked = (props: SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
        <rect x={3} y={10} width={18} height={12} rx={2} stroke="currentColor" strokeWidth={2} />
        <path d="M7 10V6a5 5 0 1 1 10 0v4" stroke="currentColor" strokeWidth={2} />
    </svg>
);

export default SvgIconLockLocked;
