import { DocumentNode, gql } from "@apollo/client";
import { Fragments } from "@api/graphql/queries/Fragments";

export class Queries {
    public static readonly me: DocumentNode = gql`
        query me {
            me {
                ...Account
            }
        }
        ${Fragments.account}
    `;

    public static readonly listDoors: DocumentNode = gql`
        query listDoors {
            listDoors {
                ...Door
            }
        }
        ${Fragments.door}
    `;

    public static readonly qrCode: DocumentNode = gql`
        query qrCode {
            qrCode
        }
    `;
}
