import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { PassPlatform, requestPass, requestPassVariables } from "@api/graphql/types";
import { Mutations } from "@api/graphql/queries/Mutations";
import { Log } from "@utils/Log";

export const Wallet = () => {
    const isIos = window.ApplePaySession !== undefined;
    const [requestApplePassMutationFn, { data: iosData }] = useMutation<requestPass, requestPassVariables>(Mutations.requestPass);

    useEffect(() => {
        if (isIos) {
            requestApplePassMutationFn({
                variables: {
                    platform: PassPlatform.IOS,
                },
            }).catch(e => {
                Log.error(e);
            });
        }
    }, [isIos, requestApplePassMutationFn]);

    if (!!iosData?.requirePass.url) {
        return (
            <a href={iosData?.requirePass.url}>
                <img src={"/img/add-apple-wallet.svg"} />
            </a>
        );
    }

    return null;
};
