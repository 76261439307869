export type Translation = {
    [key: string]: string | Translation;
};

export type IntlMessageObject = {
    [key: string]: string;
};

export const flattenMessages = (nestedMessages: Translation, prefix = ""): IntlMessageObject => {
    return Object.keys(nestedMessages).reduce((messages: IntlMessageObject, key: string) => {
        const value: Translation | string = nestedMessages[key];
        const prefixedKey: string = prefix ? `${prefix}.${key}` : key;

        if (typeof value === "string") {
            messages[prefixedKey] = value;
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey));
        }

        return messages;
    }, {});
};
