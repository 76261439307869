/* eslint-disable prettier/prettier */

// tslint:disable: no-hardcoded-credentials no-duplicate-string typedef max-line-length
export const localeHU = {
    "pages.login.title": "Belépés",
    "components.bottomMenu.group": "Csoportok",
    "components.bottomMenu.who": "Ki van?",
    "components.bottomMenu.qr": "QR-kód",
    "components.bottomMenu.door": "Ajtó",
    "components.bottomMenu.help": "Elromlott",
    "components.bottomMenu.butcher": "Hentes",
};
