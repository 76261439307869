import React from "react";
import styled from "styled-components";
import { Color } from "@theme/Theme";
import { useSelector } from "react-redux";
import { AuthSelectors } from "@redux/selectors/AuthSelectors";

export const Header = () => {
    const user = useSelector(AuthSelectors.account);

    return (
        <StyledHeader>
            <HeaderTitle>{user?.name}</HeaderTitle>
        </StyledHeader>
    );
};

const HeaderTitle = styled.span`
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    color: ${Color.greyDark};
`;

const StyledHeader = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    background-color: ${Color.white};
`;
